import React from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'
import 'moment/locale/nl'
import { graphql } from 'gatsby'

import Button from '../components/Button'
import Content from '../components/Content'
import Root from '../layouts/Root'

import config from '../config'

import styles from './nieuws.module.scss'

export default ({ data }) => {
  const { frontmatter, html, excerpt } = data.nieuws

  return (
    <Root>
      {/* prettier-ignore */}
      <Helmet>
        <title>{`${frontmatter.meta_title || frontmatter.title} | ${config.title}`}</title>
        <meta name="description" content={frontmatter.meta_description || excerpt} />
        <meta property="og:title" content={`${frontmatter.meta_title || frontmatter.title} | ${config.title}`} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={frontmatter.meta_description || excerpt} />
        {frontmatter.cover && <meta property="og:image" content={config.siteUrl + frontmatter.cover.childImageSharp.fluid.src} />}
        {frontmatter.cover && <meta property="og:image:height" content={frontmatter.cover.childImageSharp.original.height} />}
        {frontmatter.cover && <meta property="og:image:width" content={frontmatter.cover.childImageSharp.original.width} />}
      </Helmet>

      <main>
        <Content
          title={frontmatter.title}
          // prettier-ignore
          image={frontmatter.cover ? frontmatter.cover.childImageSharp.fluid : null}
          // prettier-ignore
          image_alt={frontmatter.cover_alt || frontmatter.title}
          // prettier-ignore
          cta={() => (
          <>
            <div />
            <Button color="confirm" size="large" to="/nieuws/" type="link">Bekijk alle nieuwsberichten</Button>
          </>
        )}
        >
          {frontmatter.date && (
            <time className={styles.date} dateTime={frontmatter.date}>
              {moment(frontmatter.date)
                .locale('nl')
                .format('DD MMMM YYYY')}
            </time>
          )}
          <article dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
      </main>
    </Root>
  )
}

export const query = graphql`
  query($slug: String!) {
    nieuws: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        date
        meta_title
        meta_description
        cover_alt
        cover {
          childImageSharp {
            original {
              height
              width
            }
            fluid(maxWidth: 800, maxHeight: 600, quality: 75, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
